<template>
  <div>
    <!-- 📖 - Loading State -->
    <is-loading v-if="isLoading" />
    <!-- Page Content  -->
    <b-container fluid>
      <!-- BEGIN:Form Flagging Billing Admisi MoU Massal -->
      <iq-card
        bodyClass="relative-background"
        class="iq-card-block iq-card-stretch iq-card-height"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title">Flagging Billing Admisi MoU Massal</h4>
        </template>
        <template v-slot:body>
          <!-- BEGIN:Form -->
          <div class="align-items-center p-2">
            <b-form>
              <b-form-group
                class="row mt-2"
                label-cols-sm="2"
                label="Dokumen:"
                label-for="dokumen"
              >
                <b-form-file
                  placeholder="Pilih Dokumen"
                  id="dokumen"
                  v-model="form.dokumen"
                  accept=".csv, .xlsx, .xls"
                  required
                ></b-form-file>
                <span class="text-secondary"
                  >* Ekstensi dokumen harus .csv/.xlsx/.xls</span
                >
              </b-form-group>
              <!-- Button -->
              <b-row class="pt-2 justify-content-center">
                <b-button
                  class="btn-block w-25"
                  variant="primary"
                  type="button"
                  @click="createData"
                  :disabled="!isValidForm"
                  ><i class="ri-play-fill"></i>Proses</b-button
                >
              </b-row>
            </b-form>
          </div>
          <!-- ./END:Form -->
        </template>
      </iq-card>
      <!-- ./END:Form Flagging Billing Admisi MoU Massal -->
      <!-- BEGIN:Informasi Data Mahasiswa Beasiswa -->
      <iq-card
        headerClass="bg-success rounded-top"
        bodyClass="relative-background"
        class="iq-card-block iq-card-stretch iq-card-height"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title text-white">
            Informasi Data Billing Mahasiswa Beasiswa
          </h4>
        </template>
        <template v-slot:body>
          <div class="align-items-center p-2">
            <!-- BEGIN:Petunjuk -->
            <iq-card
              class="
                iq-card-block iq-bg-info
                text-dark
                iq-card-stretch iq-card-height
                p-3
              "
            >
              <div>
                <span class="font-weight-bold"
                  >PETUNJUK FLAGGING BILLING ADMISI MoU MASSAL :</span
                >
                <b-row>
                  <b-col>
                    <div>
                      1. Sesuaikan urutan format dokumen .csv/.xlsx/.xls seperti
                      berikut:
                      <br />
                      <span class="ml-2 pl-1 font-weight-bold"
                        >Masa | Nim | Kode Beasiswa | Kode Pks | Nomor
                        Billing</span
                      >
                    </div>
                    <div>
                      2. Atau bisa download template excel dibawah ini, kemudian
                      isikan dokumen yang akan ditambahkan.
                      <br />
                      <b-row class="mt-2 text-center">
                        <b-col>
                          <b-btn
                            class="w-50 float-right btn-sm"
                            variant=" bg-success"
                            v-b-tooltip="'Unduh Excel CSV'"
                            @click="getTemplateExcel({ templateType: 'csv' })"
                            >Unduh Excel CSV</b-btn
                          >
                        </b-col>
                        <b-col>
                          <b-btn
                            class="w-50 btn-sm"
                            variant="secondary"
                            v-b-tooltip="'Unduh Excel XLSX'"
                            @click="getTemplateExcel({ templateType: 'xlsx' })"
                            >Unduh Excel XLSX</b-btn
                          >
                        </b-col>
                        <b-col>
                          <b-btn
                            class="w-50 float-left btn-sm"
                            variant="primary"
                            v-b-tooltip="'Unduh Excel XLS'"
                            @click="getTemplateExcel({ templateType: 'xls' })"
                            >Unduh Excel XLS</b-btn
                          >
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </iq-card>
            <!-- ./END:Petunjuk -->
            <!-- BEGIN:Table -->
            <div>
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25 float-right"
                    id="filter-input"
                    v-model="filterTable"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoadingTable"
                :filter="filterTable"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                show-empty
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
            <!-- ./END:Table -->
          </div>
        </template>
      </iq-card>
      <!-- ./END:Informasi Data Mahasiswa Beasiswa -->
      <!-- BEGIN:Data Mahasiswa Beasiswa yang gagal import -->
      <iq-card
        headerClass="bg-danger rounded-top"
        bodyClass="relative-background"
        class="iq-card-block iq-card-stretch iq-card-height"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title text-white">
            Data Billing Mahasiswa Beasiswa Yang Gagal Import
          </h4>
        </template>
        <template v-slot:body>
          <div class="align-items-center p-2">
            <!-- BEGIN:Table -->
            <div>
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25 float-right"
                    id="filter-input"
                    v-model="filterTableGagal"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="theadGagal"
                :items="listDataGagal"
                class="mt-2"
                :busy="isLoadingTableGagal"
                :filter="filterTableGagal"
                :per-page="perPageGagal"
                :current-page="currentPageGagal"
                @filtered="onFilteredGagal"
                show-empty
              >
                <template v-slot:cell(kode_pks)="listGagal">
                  <div class="d-flex justify-content-center">
                    {{
                      listGagal.item.kode_pks ? listGagal.item.kode_pks : '-'
                    }}
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageGagal"
                      :options="pageOptionsGagal"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageGagal"
                    :total-rows="totalRowsGagal"
                    :per-page="perPageGagal"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
            <!-- ./END:Table -->
          </div>
        </template>
      </iq-card>
      <!-- ./END:Data Mahasiswa Beasiswa yang gagal import -->
    </b-container>
  </div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Vue from 'vue'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'kerjasama.ubahStatusBillingMassal',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status = this.form.dokumen !== null
      return status
    }
  },
  async mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredGagal(filteredItems) {
      this.totalRowsGagal = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Get => Template Excel
     */
    async getTemplateExcel(data) {
      console.log('templateType: ', data.templateType)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/exportTemplate/${data.templateType}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          const fileName = 'Template Excel Import Beasiswa Format'
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                data.templateType === 'xlsx'
                  ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  : data.templateType === 'xls'
                  ? 'application/vnd.ms-excel'
                  : 'text/csv'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
    },
    /**
     * Create Data
     */
    async createData() {
      console.log('form.dokumen: ', this.form.dokumen)
      /** change file type for BE */
      this.form.dokumen.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ? (this.form.formatFile = 'xlsx')
        : this.form.dokumen.type === 'application/vnd.ms-excel'
        ? (this.form.formatFile = 'xls')
        : this.form.dokumen.type === 'text/csv'
        ? (this.form.formatFile = 'csv')
        : (this.form.formatFile = null)
      console.log('form.formatFile: ', this.form.formatFile)
      /** post data */
      if (
        this.form.dokumen.type !== 'text/csv' &&
        this.form.dokumen.type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        this.form.dokumen.type !== 'application/vnd.ms-excel'
      ) {
        this.$toast.error('Ekstensi dokumen harus Excel (.csv / .xlsx / .xls)')
      } else {
        this.isLoading = true
        const bodyFormData = new FormData()
        bodyFormData.append('file', this.form.dokumen)
        await axios({
          url: `${process.env.VUE_APP_URL_PUBLIC}/flaggingAdmisi/prosesFlaggingMouMassal`,
          method: 'POST',
          data: bodyFormData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.token
          }
        })
          .then(res => {
            this.listData = res.data.data_berhasil
            this.listDataGagal = res.data.data_gagal
            /** Set the initial number of items */
            this.totalRows = this.listData.length
            this.totalRowsGagal = this.listDataGagal.length
            this.isLoading = false
            document.body.classList.add('overflow-visible')
            this.$toast.success(res.data.message)
          })
          .catch(err => {
            console.log('ERROR', err)
            this.isLoading = false
            document.body.classList.add('overflow-visible')
            this.$toast.error(err.response.data.message)
            throw new Error(err)
          })
        this.isLoading = false
        document.body.classList.add('overflow-visible')
      }
    },
    async innitData() {
      // innit data code...
    }
  },
  data() {
    return {
      isLoading: false,
      // Template Excel
      templateType: null,
      // Tabel Options
      isLoadingTable: false,
      filterTable: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPage: 1,
      totalRows: 1,
      // Tabel Options Gagal Import
      isLoadingTableGagal: false,
      filterTableGagal: null,
      perPageGagal: 5,
      pageOptionsGagal: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageGagal: 1,
      totalRowsGagal: 1,
      // Form
      form: {
        dokumen: null,
        formatFile: null
      },
      //   List Data
      listData: [],
      listDataGagal: [],
      // Table Informasi Data Mahasiswa Beasiswa
      thead: [
        {
          label: 'Masa',
          key: 'masa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'NIM',
          key: 'nim',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nama Mahasiswa',
          key: 'nama_mahasiswa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Beasiswa',
          key: 'kode_beasiswa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode PKS',
          key: 'kode_pks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'No PKS UT',
          key: 'no_pks_ut',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'No PKS Mitra',
          key: 'no_pks_mitra',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
          class: 'text-center',
          sortable: true
        }
        // { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ],
      // Table Data Mahasiswa Beasiswa yang gagal import
      theadGagal: [
        {
          label: 'Masa',
          key: 'masa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'NIM',
          key: 'nim',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nama Mahasiswa',
          key: 'nama_mahasiswa',
          class: 'text-justify w-25',
          sortable: true
        },
        {
          label: 'Kode Beasiswa',
          key: 'kode_beasiswa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode PKS',
          key: 'kode_pks',
          class: 'text-center',
          sortable: true
        },
        { label: 'Keterangan', key: 'keterangan', class: 'text-center' }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (
      MENU_ROLE.KERJASAMA_FLAGGING_BILLING_MASSAL.includes(String(idUnitKs))
    ) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
